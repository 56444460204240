<template>
	<div
		class="full-height flex-column"
	>
		<div
			class="bg-white  full-height overflow-y-auto"
		>

			<div class="full-width ">

				<Search
					:search="item_search"
					:option="search_option"

					@change="getData"
					@click="getData"
					@toExcel="toExcel"
					class="mt-10"
				>
					<select
						v-if="auth.is_admin"
						v-model="item_search.upper_id"
						slot="add"
						class="pa-5-10 mr-10"
						@change="item_search.admin_code = ''; getAgencyList()"
					>
						<option value="">소속 총판</option>
						<option
							v-for="(agency, index) in agency_list2"
							:key="'agency_' + index"
							:value="agency.account_id"
						>{{ agency.agency_name }}</option>
					</select>
					<select
						v-if="!auth.is_agency"
						v-model="item_search.admin_code"
						slot="add"
						class="pa-5-10 mr-10"
						@change="getData"
					>
						<option value="">소속 대리점</option>
						<option
							v-for="(agency, index) in agency_list"
							:key="'agency_' + index"
							:value="agency.account_id"
						>{{ agency.agency_name }}</option>
					</select>
					<select
						v-if="false"
						slot="add"
						v-model="item_search.send_type"
						class="pa-5-10 mr-10"

						@change="getSearch(1)"
					>
						<option value="">전송 구분</option>
						<template
							v-for="(code, index) in codes.W001.items"
						>
							<option
								:key="code.total_code + '_' + index"
								:value="code.code_value"
							>{{ code.code_name }}</option>
						</template>
					</select>
					<select
						slot="add"
						v-model="item_search.status"
						class="pa-5-10 mr-10"

						@change="getSearch(1)"
					>
						<option value="">승인 상태</option>
						<template
							v-for="(code, index) in codes.U001.items"
						>
							<option
								:key="code.total_code + '_' + index"
								:value="code.code_index"
							>{{ code.code_name }}</option>
						</template>
					</select>
				</Search>

				<div
					v-if="items.length > 0"
				>
					<table
						class="mt-10 table table-even"
					>
						<colgroup>
						</colgroup>
						<thead>
						<tr>
							<th>관리 번호</th>
							<th
								v-if="auth.is_admin"
							>소속 총판</th>
							<th
								v-if="!auth.is_agency"
							>소속 대리점</th>
							<th>아이디</th>
							<th>이름</th>

							<th>입금은행</th>
							<th>입금자</th>
							<th>가상계좌 예금주</th>
							<th v-if="false">전송 구분</th>
							<th v-if="false">구분</th>
							<th>승인 상태</th>

							<th>금액</th>
							<th v-if="auth.is_admin">수수료</th>
							<th>등록일</th>
							<th>승인일</th>
							<th>관리</th>
						</tr>
						</thead>
						<tbody
							v-if="items.length > 0"
						>
						<tr
							v-for="(item, index) in list_history"
							:key="item.uid"
							:title="item.memo"
						>
							<td>{{ item.uid }}</td>
							<td
								v-if="auth.is_admin"
							>{{ item.upper_name }}</td>
							<td
								v-if="!auth.is_agency"
							>{{ item.agency_name }}</td>
							<td>{{ item.account_id }}</td>
							<td>{{ item.member_name }}</td>

							<td>{{ item.bank_name }}</td>
							<td>{{ item.bank_depositor }}</td>
							<td>{{ item.bank_holder }}</td>
							<td v-if="false">{{ item.send_type_name }}</td>
							<td v-if="false" :class="'color-' + item.history_type_color">{{ item.history_type_name }}</td>
							<td :class="'color-' + item.status_color">{{ item.status_name }}</td>
							<td :class="'color-' + item.status_color">{{ item.amount | makeComma }}원</td>
							<td v-if="auth.is_admin">{{ item.fee | makeComma }}원</td>
							<td>{{ item.wDate }}</td>
							<td>{{ item.sDate }}</td>

							<td>
								<div>
								<button
									class="bg-identify pa-5-10"
									@click="onMemo(item)"
								>상세 정보</button>
								</div>
								<div
									v-if="item.is_confirm"
								>
								<button
									class="mt-10 bg-success pa-5-10"
									@click="onConfirm(item, index)"
								>승인 처리</button>
								</div>
								<div
									v-else-if="item.is_cancel"
								>
								<button
									class="mt-10 bg-red pa-5-10"
									@click="onCancel(item, index)"
								>승인 취소</button>
								</div>
							</td>
						</tr>
						</tbody>
					</table>

					<Pagination
						:program="program"
						:align="'center'"
						:options="item_search"

						class="mt-auto"
						@click="getSearch"
					></Pagination>
				</div>
				<Empty
					v-else
				></Empty>
			</div>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@close="is_excel = false"
		></Excel>

		<PopupLayer
			v-if="is_on_confirm"

			@cancel="is_on_confirm = false"
			@click="postConfirm"
		>
			<template
				v-slot:body
			>
				<div class="bg-white width-320 ma-auto flex-column justify-space-between " style="max-height: 480px">
					<div class="pa-10-20 justify-space-between under-line">
						<h4>입금 처리</h4>
						<v-icon
							@click="is_on_confirm = false"
						>mdi-close</v-icon>
					</div>

					<div
						class="overflow-y-auto"
					>
						<div class="pa-10-20">
							등록된 입금계좌정보와 일치하지 않은 입금 정보입니다. 승인처리 하시겠습니까?
						</div>

						<div class="pa-10-20">
							<textarea
								v-model="memo"
								class="input-box pa-10"
								placeholder="승인 처리시 메모를 입력하세요"
								maxlength="200"
								rows="5"
							></textarea>
						</div>
					</div>

					<div
						class="mt-auto"
					>
						<button
							@click="postConfirm"
							class="btn btn-primary mr-10"
							:disabled="!memo"
						><slot name="name-confirm">입금 승인</slot></button>
					</div>
				</div>
			</template>

		</PopupLayer>

		<PopupLayer
			v-if="is_on_cancel"

			@cancel="is_on_cancel = false"
		>
			<template
				v-slot:body
			>
				<div class="bg-white width-320 ma-auto flex-column justify-space-between " style="max-height: 480px">
					<div class="pa-10-20 justify-space-between under-line">
						<h4>입금 취소</h4>
						<v-icon
							@click="is_on_cancel = false"
						>mdi-close</v-icon>
					</div>

					<div
						class="overflow-y-auto"
					>
						<div class="pa-10-20">
							<textarea
								v-model="memo"
								class="input-box pa-10"
								placeholder="입금 취소시 메모를 입력하세요"
								maxlength="200"
								rows="5"
							></textarea>
						</div>
					</div>

					<div
						class="mt-auto"
					>
						<button
							@click="postCancel"
							class="btn btn-primary mr-10"
							:disabled="!memo"
						><slot name="name-confirm">입금 취소</slot></button>
					</div>
				</div>
			</template>

		</PopupLayer>

		<PopupLayer
			v-if="is_on_memo"

			@cancel="is_on_memo = false"
		>
			<template
				v-slot:body
			>
				<div class="bg-white width-720 ma-auto flex-column justify-space-between " style="max-height: 480px">
					<div class="pa-10-20 justify-space-between under-line">
						<h4>처리 내역</h4>
						<v-icon
							@click="is_on_memo = false"
						>mdi-close</v-icon>
					</div>

					<div
						class="pa-20 overflow-y-auto"

					>
						<div>
							<table class="table table-even">
								<thead>
									<tr>
										<th>이름</th>
										<th>입금자</th>
										<th>가상계좌 예금주</th>
										<th>상태</th>
										<th>금액</th>
										<th>TID</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{ item_confirm.member_name }}</td>
										<td>{{ item_confirm.bank_depositor }}</td>
										<td>{{ item_confirm.bank_holder }}</td>
										<td :class="'color-' + item_confirm.status_color">{{ item_confirm.status_name }}</td>
										<td :class="'color-' + item_confirm.history_type_color">{{ item_confirm.amount | makeComma }}원</td>
										<td class="word-break td-left text-left">{{ item_confirm.tid }}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="mt-20" :inner-html.prop="item_confirm.memo | nl2br"></div>
					</div>

					<div
						class="mt-auto"
					>
						<button
							@click="is_on_memo = false"
							class="btn btn-primary mr-10"
						><slot name="name-confirm">확인</slot></button>
					</div>
				</div>
			</template>

		</PopupLayer>
	</div>
</template>

<script>

import Search from "../Layout/Search";
import Pagination from "../../components/Pagination";
import Empty from "../Layout/Empty";
import Excel from "@/components/Excel";
import PopupLayer from "@/view/Layout/PopupLayer";

export default {
	name: 'WalletHistory'
	, props: ['user', 'codes', 'virtual_uid', 'auth', 'date']
	, components: {PopupLayer, Excel, Empty, Search, Pagination}
	, data: function(){
		return {
			program: {
				name: '승인 입금 내역'
				,top: true
				,title: true
			}
			, items: []
			, item_search: {
				virtual_uid: this.$route.params.idx
				, virtual_status: ''
				, virtual_able: ''
				, list_cnt: 10
				, search_type: 'member_name'
				, is_use: ''
				, total_count: 0
				, page: 1
				, tCnt: 0
				, send_type: '9'
				, history_type: ''
				, sDate: this.$date.getLastDate(7, '-')
				, eDate: this.$date.getToday('-')
				, upper_id: ''
				, admin_code: ''
				, status: ''
			}
			,search_option:{

				is_cnt: true
				, is_excel: true
				, cnt: 0
				, tCnt: 0
				, search_type: [
					{ name: '아이디', column: 'member_id'}
					, { name: '이름', column: 'member_name'}
					, { name: '입금자', column: 'bank_depositor'}
				]
				, sDate: true
				, eDate: true
			}
			, agency_list: []
			, agency_list2: []
			, is_excel: false
			, excel_data: {
				name: '승인 입금 내역'
				,header: [
					{ key: 0, name: '소속 총판', column: 'upper_name'}
					,{ key: 0, name: '소속 대리점', column: 'agency_name'}
					,{ key: 0, name: '아이디', column: 'member_id'}
					,{ key: 0, name: '이르', column: 'member_name'}
					,{ key: 0, name: '입금자', column: 'bank_depositor'}
					,{ key: 0, name: '가상계좌 예금주', column: 'bank_holder'}
					,{ key: 0, name: '승인상태', column: 'status_name'}
					,{ key: 0, name: '금액', column: 'amount'}
					,{ key: 0, name: '수수료', column: 'fee'}
					,{ key: 0, name: '메모', column: 'memo'}
					,{ key: 0, name: '등록일', column: 'wDate'}
				]
				,content: null
			}
			, is_on_confirm: false
			, item_confirm:{

			}
			, memo: ''
			, is_on_cancel: false
			, is_on_memo: false
			, items_bank: []
			, items_virtual: []
		}
	}
	, computed: {
		list_history: function(){
			return this.items.filter( ( item ) => {
				switch (item.history_type){
					case '0':
						item.history_type_name = '출금'
						item.amount *= -1
						break;
					case '1':
						item.history_type_name = '입금'
						break;
				}
				this.codes["W001"].items.filter( (status) => {
					if(item.send_type == status.code_index){

						item.send_type_name = status.code_name
						item.send_type_color = status.code_color
					}
				})

				this.codes["U001"].items.filter( (status) => {
					if(item.status == status.code_index){
						item.status_name = status.code_name
						item.status_color = status.code_color
					}
				})


				if(item.bank_depositor != item.bank_holder){
					if(item.status == '1'){
						if(item.sDate?.substring(0, 10) == this.$date.getToday('-')){
							item.is_cancel = true
						}
					}else{
						item.is_confirm = true
					}
				}

				this.items_bank.filter( (bank) => {
					if(item.bank_code == bank.code){
						item.bank_name = bank.bankName
					}
				})
				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getUserWalletHistory'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.items_bank = result.data.bank_list
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(page){
				this.item_search.page = page
			}

			this.getData()
		}
		,getAgencyList2: async function() {
			if(this.user.role != this.codes.type_code_admin && this.user.route != this.codes.type_code_distributor){
				// return false
			}
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyList'
					,data: {
						agency_type: 'A001002'
						, list_cnt: 100
					}
				})

				if(result.success){
					this.agency_list2 = result.data.result
					//this.search_option.select[0].items = this.select_option_agency
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}
		}
		,getAgencyList: async function() {
			if(this.user.role != this.codes.type_code_admin && this.user.route != this.codes.type_code_distributor){
				// return false
			}
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyList'
					,data: {
						agency_type: 'A001003'
						, upper_id: this.item_search.upper_id
						, type: 'n'
						, list_cnt: 100
					}
				})

				if(result.success){
					this.agency_list = result.data.result
					//this.search_option.select[0].items = this.select_option_agency
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}
		}
		,toExcel: function(){
			this.excel_data.content = this.list_history
			this.is_excel = true
		}
		, postConfirm: async function(){
			if(confirm("등록된 입금계좌정보와 일치하지 않은 입금 정보입니다. 승인처리 하시겠습니까?")){
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'management/postWalletDepositConfirm'
						,data: {
							history_uid: this.item_confirm.uid
							, memo: this.memo
						}
					})

					if(result.success){
						await this.getData()
						this.$bus.$emit('notify', { type: 'success', message: result.message })
						this.is_on_confirm = false
						this.item_confirm = {

						}
						this.memo = ''
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}
		}
		, onConfirm: function(item, index){
			item.index = index
			this.item_confirm = item
			this.is_on_confirm = true
		}
		, onCancel: function(item, index){
			item.index = index
			this.item_confirm = item
			this.is_on_cancel = true
		}
		, postCancel: async function(){

			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postWalletDepositCancel'
					,data: {
						history_uid: this.item_confirm.uid
						, memo: this.memo
					}
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: result.message })
					this.is_on_cancel = false
					this.item_confirm = {

					}
					this.memo = ''
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}
		}
		, onMemo: function(item){
			this.item_confirm = item
			this.is_on_memo = true
		}
		, getVirtualList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getVirtualList'
					, data: {
					}
				})
				if (result.success) {
					this.items_virtual = result.data
					this.item_search.virtual_uid = this.items_virtual[0].uid
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getAgencyList2()
		await this.getAgencyList()
		await this.getVirtualList()
		await this.getData()
	}
}
</script>

<style>
.tab h6 { border-right: 1px solid white; cursor: pointer}
</style>